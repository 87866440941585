import React from 'react'

const Footer = () => {
  return (
    <div className='text-center font-bold relative bottom-0 right-0  p-2 overflow-hidden bg-black text-white width-[100em]'>
      Designed and Developed by aaryae .{' '}
    </div>
  )
}

export default Footer
